import { createApp } from 'vue';
import { createPinia } from 'pinia';
import useAuthStore from '@/stores/auth';
import VueSplide from '@splidejs/vue-splide';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import App from './App.vue';
import router from './router';
import { injectStore, injectRouter } from './services/Ky';

import './assets/tailwind.css';
// eslint-disable-next-line import/extensions
import '@splidejs/vue-splide/css';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

injectStore(useAuthStore(pinia));
injectRouter(router);

createApp(App)
  .use(pinia)
  .use(router)
  .use(VueSplide)
  .mount('#app');
