import { defineStore } from 'pinia';
import kyInstance from '@/services/Ky';
import { ref, computed } from 'vue';

// import useAuthStore from '@/stores/auth';

// const authStore = useAuthStore();

export default defineStore(
  'propertyPostulation',
  () => {
    const selectedType = ref('');
    const selectedpropertyType = ref('');
    const selectedOperationType = ref('');
    const filteredApplications = ref([]);
    const createdPostulations = ref([]);
    const selectedPostulation = ref(null);

    const basicInfo = ref({});
    const basicInfoComponents = ref([]);
    const locationInfo = ref({
      locationSearch: {
        type: null, state: null, town: null, suburb: null,
      },
    });
    const locationMetaData = ref({
      type: null,
      isState: false,
      state: null,
      options: [],
      town: null,
    });
    const operationInfo = ref({});

    const formErrors = ref([]);
    const paginationMeta = ref(null);

    const basicInfoErrors = computed(() => formErrors.value.filter((error) => error.field.indexOf('property') === 0));
    const locationInfoErrors = computed(() => formErrors.value.filter((error) => error.field.indexOf('location') === 0));
    const operationInfoErrors = computed(() => formErrors.value.filter((error) => error.field[1].indexOf('operation') === 0));

    const propertyNotifications = ref([]);
    const isPostulationPosponed = ref(false);

    const resetErrors = () => {
      formErrors.value = [];
    };

    const validatePropertyPostulation = async (payload) => {
      try {
        resetErrors();
        await kyInstance.post('property-postulations/validation', {
          json: {
            ...payload,
            ...basicInfo.value,
            ...locationInfo.value,
            ...operationInfo.value,
          },
        });
      } catch (error) {
        const { errors } = await error.response.json();
        formErrors.value = await errors;
      }
    };

    const filterApplicationsByPostulation = async (payload) => {
      try {
        const response = await kyInstance.post('property-postulations/query', {
          json: {
            ...payload,
            ...basicInfo.value,
            ...locationInfo.value,
            ...operationInfo.value,
          },
        }).json();
        filteredApplications.value = response;
      } catch (error) {
        console.log(error.response.status, 'error');
      }
    };

    const recreateFilter = async (payload) => {
      basicInfo.value = payload.basic_info;
      locationInfo.value = payload.location_info;
      operationInfo.value = payload.operation_info;
      await filterApplicationsByPostulation({
        type: payload.type,
        propertyType: payload.property_type,
        savedPostulationId: payload.id,
      });
    };

    const createSavedProperty = async (payload) => {
      const info = {
        ...payload,
        ...basicInfo.value,
        ...locationInfo.value,
        ...operationInfo.value,
      };
      const res = await kyInstance.post('property/save', { json: info }).json();
      return res;
    };

    const postPropertyPostulation = async (payload) => {
      const info = {
        ...payload,
        ...basicInfo.value,
        ...locationInfo.value,
        ...operationInfo.value,
      };
      const res = await kyInstance.post('property-postulations', { json: info }).json();
      return res;
    };

    const savePropertyPostulation = async (payload) => {
      await kyInstance.patch(`property-postulations/save/${payload.postulationId}`);
    };

    const deletePropertyPostulation = async (payload) => {
      const response = await kyInstance.patch(`property-postulations/delete/${payload}`).json();
      return response;
    };

    const $reset = () => {
      localStorage.setItem('propertyPostulation', null);
      selectedpropertyType.value = '';
      selectedOperationType.value = '';
      filteredApplications.value = [];
      createdPostulations.value = [];
      selectedPostulation.value = null;

      basicInfo.value = {};
      basicInfoComponents.value = [];
      locationInfo.value = {
        locationSearch: {
          type: null, state: null, town: null, suburb: null,
        },
      };
      locationMetaData.value = {
        type: null,
        isState: false,
        state: null,
        options: [],
        town: null,
      };
      operationInfo.value = {};

      formErrors.value = [];
      paginationMeta.value = null;

      propertyNotifications.value = [];
      isPostulationPosponed.value = false;
    };

    const acceptPostulation = async (payload) => {
      await kyInstance.post(`property-postulations/accept/${payload}`);
    };

    const getCreatedPostulations = async (payload) => {
      const {
        page, orderBy, order, isSaved,
      } = payload;
      const response = await kyInstance.get(`property-postulations/user?page=${page}&orderBy=${orderBy}&order=${order}&isSaved=${isSaved}`).json();
      createdPostulations.value = response.data;
      paginationMeta.value = response.meta;
    };

    const getPostulationById = async (payload) => {
      const response = await kyInstance.get(`property-postulations/${payload}`).json();
      selectedPostulation.value = response;
    };

    const changePostulationType = async (payload) => {
      selectedType.value = payload;
    };

    const getPropertyNotifications = async () => {
      const response = await kyInstance.get('property-notifications').json();
      propertyNotifications.value = response;
    };

    const postponePostulation = async () => {
      isPostulationPosponed.value = true;
    };

    const resetPropertyPostulation = async () => {
      localStorage.setItem('propertyPostulation', null);
      selectedpropertyType.value = '';
      selectedOperationType.value = '';
      filteredApplications.value = [];
      createdPostulations.value = [];
      selectedPostulation.value = null;

      basicInfo.value = {};
      basicInfoComponents.value = [];
      locationInfo.value = {
        locationSearch: {
          type: null, state: null, town: null, suburb: null,
        },
      };
      locationMetaData.value = {
        type: null,
        isState: false,
        state: null,
        options: [],
        town: null,
      };
      operationInfo.value = {};

      formErrors.value = [];
      paginationMeta.value = null;

      propertyNotifications.value = [];
      isPostulationPosponed.value = false;
    };

    return {
      selectedpropertyType,
      selectedOperationType,
      filteredApplications,

      basicInfo,
      basicInfoComponents,
      locationInfo,
      locationMetaData,
      operationInfo,

      formErrors,
      basicInfoErrors,
      locationInfoErrors,
      operationInfoErrors,

      resetErrors,
      filterApplicationsByPostulation,
      recreateFilter,
      validatePropertyPostulation,
      postPropertyPostulation,
      savePropertyPostulation,
      acceptPostulation,

      createSavedProperty,
      deletePropertyPostulation,

      createdPostulations,
      getCreatedPostulations,

      propertyNotifications,
      getPropertyNotifications,

      isPostulationPosponed,
      postponePostulation,

      selectedPostulation,
      getPostulationById,

      selectedType,
      changePostulationType,

      paginationMeta,

      $reset,
      resetPropertyPostulation,
    };
  },
  {
    persist: true,
  },
);
