<template>
  <router-view/>
</template>

<script setup>
import { onBeforeMount, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import useAuthStore from '@/stores/auth';

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);
const { getLoggedUser } = authStore;

const router = useRouter();
const route = useRoute();

const routeValidation = async (path) => {
  const guestRoutes = ['/login', '/forgot-password', '/new-password', '/set-password'];
  const isInGuestRoutes = guestRoutes.includes(path);

  if (user.value == null && !isInGuestRoutes) {
    router.push({ name: 'LoginView' });
  } else if (user.value != null && isInGuestRoutes) {
    if (route.query.redirectUrl) {
      router.push(route.query.redirectUrl);
    } else {
      router.push({ name: 'DashboardView' });
    }
  }
};

watch(
  () => user.value,
  (value, oldValue) => {
    if (oldValue != null && value == null) {
      router.push({ name: 'LoginView' });
    }
  },
);

// onBeforeMount(async () => {
//   window.addEventListener('beforeunload', (event) => {
//     event.preventDefault();
//     // eslint-disable-next-line no-param-reassign
//     event.returnValue = '';
//   });
// });

onBeforeMount(async () => {
  try {
    await getLoggedUser();
    await router.isReady();
    await routeValidation(route.path);
  } catch (error) {
    // console.log(error, 'error');
  }
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
