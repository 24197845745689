import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import('@/views/Authentication/LoginView.vue'),
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: () => import('@/views/Authentication/RegisterView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: () => import('@/views/Authentication/ForgotPasswordView.vue'),
  },
  {
    path: '/check-email',
    name: 'CheckEmailView',
    component: () => import('@/views/Authentication/CheckEmailView.vue'),
  },
  {
    path: '/email-verification',
    name: 'VerifyEmailView',
    component: () => import('@/views/Authentication/VerifyEmailView.vue'),
  },
  {
    path: '/email-verification/:token',
    name: 'VerifyEmailSuccessView',
    component: () => import('@/views/Authentication/VerifyEmailSuccessView.vue'),
  },
  {
    path: '/set-password',
    name: 'ResetPasswordView',
    component: () => import('@/views/Authentication/ResetPasswordView.vue'),
  },
  {
    path: '/set-password/success',
    name: 'ResetPasswordSuccessView',
    component: () => import('@/views/Authentication/ResetPasswordSuccessView.vue'),
  },
  {
    name: 'UserTokenValidation',
    path: '/user-token-validation',
    component: () => import('@/views/UserTokenValidation.vue'),
    children: [
      {
        path: '/property-application',
        name: 'NewPropertyApplicationView',
        component: () => import('@/views/PropertyApplication/NewPropertyApplicationView.vue'),
      },
      {
        path: '/property-application/info',
        name: 'BasicInfoFormView',
        component: () => import('@/views/PropertyApplication/BasicInfoFormView.vue'),
      },
      {
        path: '/property-application/location',
        name: 'LocationFormView',
        component: () => import('@/views/PropertyApplication/LocationFormView.vue'),
      },
      {
        path: '/property-application/operation',
        name: 'OperationFormView',
        component: () => import('@/views/PropertyApplication/OperationFormView.vue'),
      },
      {
        path: '/property-application/complete-application',
        name: 'CompletePropertyApplicationView',
        component: () => import('@/views/PropertyApplication/CompletePropertyApplicationView.vue'),
      },
    ],
  },
  {
    name: 'UserTokenAndPhoneValidation',
    path: '/user-token-phone-validation',
    component: () => import('@/views/UserTokenAndPhoneValidation.vue'),
    children: [
      {
        path: '/property-postulation/complete-postulation',
        name: 'CompletePropertyPostulationView',
        component: () => import('@/views/PropertyPostulation/CompletePropertyPostulationView.vue'),
      },
    ],
  },
  {
    path: '/property-postulation',
    name: 'NewPropertyPostulationView',
    component: () => import('@/views/PropertyPostulation/NewPropertyPostulationView.vue'),
  },
  {
    path: '/property-postulation/info',
    name: 'PostulationBasicInfoFormView',
    component: () => import('@/views/PropertyPostulation/PostulationBasicInfoFormView.vue'),
  },
  {
    path: '/property-postulation/location',
    name: 'PostulationLocationFormView',
    component: () => import('@/views/PropertyPostulation/PostulationLocationFormView.vue'),
  },
  {
    path: '/property-postulation/operation',
    name: 'PostulationOperationFormView',
    component: () => import('@/views/PropertyPostulation/PostulationOperationFormView.vue'),
  },
  {
    path: '/property-postulation/search-results',
    name: 'PostulationSearchResultsView',
    component: () => import('@/views/PropertyPostulation/PostulationSearchResultsView.vue'),
  },
  {
    path: '/property-postulation/property-details',
    name: 'PostulationPropertyDetailsView',
    component: () => import('@/views/PropertyPostulation/PostulationPropertyDetailsView.vue'),
  },
  {
    path: '/property-postulation/complete-postulation',
    name: 'CompletePropertyPostulationView',
    component: () => import('@/views/PropertyPostulation/CompletePropertyPostulationView.vue'),
  },
  // {
  //   name: 'UserPhoneVerifiedValidation',
  //   path: '/user-phone-validation',
  //   component: () => import('@/views/UserPhoneVerifiedValidation.vue'),
  //   children: [
  //   ],
  // },
  {
    path: '/profile',
    name: 'MyProfileView',
    component: () => import('@/views/ProfileConfiguration/MyProfileView.vue'),
  },
  {
    path: '/account',
    name: 'MyAccountView',
    component: () => import('@/views/ProfileConfiguration/MyAccountView.vue'),
  },
  {
    path: '/notifications',
    name: 'NotificationConfigurationsView',
    component: () => import('@/views/ProfileConfiguration/NotificationConfigurationsView.vue'),
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: () => import('@/views/Main/DashboardView.vue'),
  },
  {
    path: '/applications',
    name: 'UserApplicationsList',
    component: () => import('@/views/Main/UserApplicationsList.vue'),
  },
  {
    path: '/postulations',
    name: 'UserPostulationsList',
    component: () => import('@/views/Main/UserPostulationsList.vue'),
  },
  {
    path: '/postulations/user',
    name: 'UserCreatedPostulationsList',
    component: () => import('@/views/Main/UserCreatedPostulationsList.vue'),
  },
  {
    path: '/postulations/saved/user',
    name: 'UserSavedPostulationsList',
    component: () => import('@/views/Main/UserSavedPostulationsList.vue'),
  },
  {
    path: '/postulations/details',
    name: 'UserPostulationDetailsView',
    component: () => import('@/views/Main/UserPostulationDetailsView.vue'),
  },
  {
    path: '/applications/details',
    name: 'UserApplicationDetailsView',
    component: () => import('@/views/Main/UserApplicationDetailsView.vue'),
  },
  {
    path: '/messages',
    name: 'UserMessagesView',
    component: () => import('@/views/Main/UserMessagesView.vue'),
  },
  {
    path: '/shop',
    name: 'TokenShopView',
    component: () => import('@/views/Main/TokenShopView.vue'),
  },
  {
    path: '/checkout/success',
    name: 'CheckoutSuccessView',
    component: () => import('@/views/Main/CheckoutSuccessView.vue'),
  },
  {
    path: '/checkout/cancelled',
    name: 'CheckoutCancelledView',
    component: () => import('@/views/Main/CheckoutCancelledView.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Main/Test.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
