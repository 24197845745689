import { defineStore } from 'pinia';
import usePropertyPostulationStore from '@/stores/propertyPostulation';
import kyInstance from '@/services/Ky';
import { ref, computed } from 'vue';

export default defineStore('auth', () => {
  const user = ref(null);
  const propertyApplications = ref([]);
  const userPostulationsList = ref([]);
  const selectedPostulation = ref(null);

  const paginationMeta = ref(null);

  const setUser = (payload) => {
    user.value = payload;
  };

  const hasEnoughTokens = computed(() => {
    if (user.value !== null) {
      return user.value.tokenBalance >= 1;
    }
    return false;
  });

  const hasPhoneVerified = computed(() => {
    if (user.value !== null) {
      return user.value.phoneVerified;
    }
    return false;
  });

  const getInitials = computed(() => {
    if (user.value !== null) {
      const name = user.value.name.split(' ');
      if (name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0);
      }
      return name[0].charAt(0);
    }
    return '';
  });

  const getLoggedUser = async () => {
    try {
      const response = await kyInstance.get('user').json();
      user.value = response;
    } catch (error) {
      user.value = null;
    }
  };

  const login = async (payload) => {
    const response = await kyInstance.post('login', { json: payload }).json();
    user.value = response;
  };

  const logout = async () => {
    await kyInstance.post('logout');
    user.value = null;
    const propertyPostulationStore = usePropertyPostulationStore();

    await propertyPostulationStore.resetPropertyPostulation();
    // await propertyPostulationStore.resetPropertyPostulation();
  };

  const register = async (payload) => {
    await kyInstance.post('register', { json: payload });
  };

  const verifyEmail = async (payload) => {
    await kyInstance.post('verify-email', { json: payload });
  };

  const forgotPassword = async (payload) => {
    await kyInstance.post('forgot-password', { json: payload });
  };

  const resetPassword = async (payload) => {
    await kyInstance.post('reset-password', { json: payload });
  };

  const getUserPropertyApplications = async (payload) => {
    const { page, orderBy, order } = payload;
    const query = `property-applications/user?page=${page}&orderBy=${orderBy}&order=${order}`;
    try {
      const response = await kyInstance.get(query).json();
      propertyApplications.value = response.data;
      paginationMeta.value = response.meta;
    } catch (error) {
      // console.log('error fetching property applications');
    }
  };

  const getUserPropertyPostulationsList = async (payload) => {
    try {
      // const query = `/property-postulations/list/${payload}`;
      const response = await kyInstance.get(`property-postulations/list/${payload}`).json();
      userPostulationsList.value = response;
    } catch (error) {
      console.log('error fetching property postulations List');
    }
  };

  const getUserPropertyPostulation = async (payload) => {
    try {
      // const query = `/property-postulations/${payload}`;
      // console.log(query, 'query', payload);
      const response = await kyInstance.get(`property-postulations/${payload}`).json();
      selectedPostulation.value = response;
    } catch (error) {
      console.log('error fetching property postulations');
    }
  };

  return {
    user,
    setUser,
    getLoggedUser,
    getInitials,
    login,
    logout,
    register,
    verifyEmail,
    forgotPassword,
    resetPassword,
    hasEnoughTokens,
    hasPhoneVerified,

    propertyApplications,
    getUserPropertyApplications,
    getUserPropertyPostulationsList,
    getUserPropertyPostulation,

    userPostulationsList,
    selectedPostulation,

    paginationMeta,
  };
});
