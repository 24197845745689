import ky from 'ky';
// import { useRouter } from 'vue-router';
const baseUrl = process.env.VUE_APP_BACKEND_URL;

// console.log(baseUrl, 'baseUrl');

let store;
let router;

export const injectStore = (_store) => {
  store = _store;
};

export const injectRouter = (_router) => {
  router = _router;
};

const kyInstance = ky.create({
  prefixUrl: baseUrl,
  credentials: 'include',
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (!response.ok) {
          // Authentication error
          if (response.status === 401 && store) {
            store.setUser(null);
          }
          if (response.status === 402 && router) {
            router.push({ name: 'DashboardView' });
          }
        }
      },
    ],
  },
});

export default kyInstance;
